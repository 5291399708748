import React, { useState } from "react"
import { useForm } from "@formcarry/react"

const ContactForm = () => {
  const [formSubmitted, _setFormSubmitted] = useState(false)
  const [formErrors, _setFormErrors] = useState(false)

  const { state, submit } = useForm({
    id: "qknVWgRGj",
  })

  if (state.submitted) {
    document.location = "/obrigado-orcamento"
  }

  return (
    <>
      <form onSubmit={submit}>
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="email"
        >
          Email
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Seu email"
            name="_replyto"
            required
            aria-label="input em formulário para email"
          />
        </label>

        <label
          className="block text-gray-700 text-sm font-bold mb-2 mt-4"
          htmlFor="name"
        >
          Nome
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Seu nome"
            name="nome"
            required
            aria-label="input em formulário para nome"
          />
        </label>
        <label
          className="block text-gray-700 text-sm font-bold mb-2 mt-4"
          htmlFor="message"
        >
          Explique o que você precisa
          <textarea
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-48"
            id="message"
            name="mensagem"
            required
            aria-label="area do formulário para escrita da mensagem"
          />
        </label>
        <button
          className="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-2 w-full"
          type="submit"
          aria-label="Botão para enviar o formulário de contato"
          disabled={state.submitted}
        >
          Enviar
        </button>
        <input type="hidden" name="_gotcha" />
      </form>

      {formSubmitted && (
        <p className="font-bold text-green-600 text-lg mt-4">
          Recebi sua mensagem, vou responder o mais rápido possível.
        </p>
      )}
      {formErrors && (
        <p className="font-bold text-red-600 text-lg mt-4">
          Desculpe, ocorreu um erro ao enviar sua mensagem. Por favor, atualize
          a página e tente novamente.
        </p>
      )}
    </>
  )
}

export default ContactForm
