import React from "react"

import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import ContactForm from "../components/ContactForm"

const RequestBudget = () => {
  return (
    <Layout>
      <SiteMetadata
        title="Pri Bellafronte - Designer de marcas e logos | Solicitar orçamento"
        description="Solicite orçamento para serviços de design gráfico, como criação de logos, papelaria, entre outros"
      />
      <div className="container py-12 lg:pb-16 px-8 md:px-32 lg:px-64">
        <p className="mb-8">
          Olá! Obrigado pelo interesse em trabalhar comigo. Através do
          formulário abaixo você pode me enviar uma mensagem me contando o que
          você precisa, para que eu possa lhe enviar um orçamento para seu
          projeto.
        </p>
        <ContactForm />
      </div>
    </Layout>
  )
}

export default RequestBudget
